<template>
  <div class="wrapper">
    <div id="anipang-video"
         class="section0 page-header">
      <div class="brand">
        <h1>
          <img :src="resource.section0.title" alt="영웅은 영원해!"/>
        </h1>
        <br>
        <br>
        <div class="video_button" style="opacity: 1;">
          <button @click="openYoutubeModal(resource.section3.content.c1.youtubeId)"></button>
        </div>
        <br>
        <br>
        <br>
        <div class="link-button">
          <a href="https://anipang2.onelink.me/KXv0/a270teei" target="_blank"><img :src="resource.button.link1" alt="애니팡2"/></a>
          <a href="https://anipang3.onelink.me/BAqa/z23jht36" target="_blank"><img :src="resource.button.link2" alt="애니팡3"/></a>
          <a href="https://ani4.onelink.me/k8vU/ce8xpux7" target="_blank"><img :src="resource.button.link3" alt="애니팡4"/></a>
        </div>
<!--        <div class="main_bt_emoji">-->
<!--          <img :src="resource.section0.main_bt_emoji" alt="영웅은 영원해!"/>-->
<!--        </div>-->
      </div>
      <video id="bg_video"
             class="bg-video"
             playsinline
             autoplay
             loop
             muted
             :poster="resource.section0.poster">
        <source type="video/mp4"
                :src="resource.section0.mp4">
      </video>
    </div>
    <!--
    <div id="shortcut-star" class="section1 section">
      <br><br>
      <h2 class="title"><img :src="resource.section1.title"></h2>
      <br><br>
      <div class="container">
        <div>
          <br><br>
          <h2><img :src="resource.section1.event1.title"/></h2>
          <br><br>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <router-link to="/games/anipnag2emoticon"><img :src="resource.section1.event1.a2"/></router-link>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <router-link to="/games/anipnag3emoticon"><img :src="resource.section1.event1.a3"/></router-link>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <router-link to="/games/anipnag4emoticon"><img :src="resource.section1.event1.a4"/></router-link>
            </div>
          </div>
        </div>
        <br>
        <div>
          <br><br>
          <h2><img :src="resource.section1.event2.title"/></h2>
          <br><br>
          <div class="md-layout">
            <a href="https://anipang2.onelink.me/KXv0/lzdqg0u5" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
              <img :src="resource.section1.event2.a2"/>
            </a>
            <a href="https://anipang3.onelink.me/BAqa/2hlxrckq" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
              <img :src="resource.section1.event2.a3"/>
            </a>
            <a href="https://ani4.onelink.me/k8vU/dk4faxgr" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
              <img :src="resource.section1.event2.a4"/>
            </a>
          </div>
          <div class="warning">
            <img :src="resource.section1.event2.text" alt="※ 각 게임별 이벤트 내용, 기간, 보상 수령 기준이 상이하므로 상세 내용은 각 게임 내에서 확인 부탁드립니다."/>
          </div>
          <br><br><br>
        </div>
      </div>
    </div>
    -->
    <div id="shortcut-series" class="section2 section">
      <div class="container">
        <div class="title">
          <br><br>
          <h2><img :src="resource.section2.title"></h2>
          <br>
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <router-link to="/games/anipnag2">
                <img :src="resource.section2.event1.a2"/>
              </router-link>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <router-link to="/games/anipnag3">
                <img :src="resource.section2.event1.a3"/>
              </router-link>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <router-link to="/games/anipnag4">
                <img :src="resource.section2.event1.a4"/>
              </router-link>
            </div>
          </div>
        </div>
        <br><br><br>
      </div>
    </div>

    <div id="shortcut-media" class="section3 section">
      <div class="container">
        <div class="title">
          <br><br>
          <h2><img :src="resource.section3.title"></h2>
          <br><br>
          <div class="md-layout">
            <div
              v-for="(item, key) in resource.section3.content"
              :key="key"
              class="md-layout-item md-size-50 md-small-size-100"
            >
              <button class="media-item"
                      @click="openYoutubeModal(item.youtubeId)">
                <img :src="item.btn"/>
              </button>
            </div>
<!--            <div class="md-layout-item md-size-50 md-small-size-100">-->
<!--              <div style="padding: 1px 6px;">-->
<!--                <img :src="resource.section3.comingSoon">-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
        <div class="more-info">
          <a href="https://pf.kakao.com/_xoxgxdxob"
             target="_blank">
            <img :src="resource.section3.more" alt="더 많은 영웅이 궁금하다면?"/></a>
        </div>
        <br><br><br><br>
      </div>
    </div>

    <div class="section4 section">
      <div class="container">
        <div class="title">
          <br><br>
          <h2><img :src="resource.section4.title"></h2>
          <br>
          <div class="md-layout">
            <a href="https://anisachun.onelink.me/q1Pv/wh8lq2m2" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
              <img :src="resource.section4.event1.a2"/>
            </a>
            <a href="https://shanghai.onelink.me/cMfx/oqk4cmmz" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
              <img :src="resource.section4.event1.a3"/>
            </a>
            <a href="https://anipangtouch.onelink.me/NQWt/bhpjit98" target="_blank" class="md-layout-item md-size-33 md-small-size-100">
              <img :src="resource.section4.event1.a4"/>
            </a>
          </div>
          <div class="more-info">
            <br><br><br>
            <div>
              <img :src="resource.section4.event1.text" alt="이제 카카오톡채널로 애니팡 소식을 받아보세요!"/>
            </div>
            <br>
            <a href="https://pf.kakao.com/_xoxgxdxob"
               target="_blank">
              <img :src="resource.section4.event1.btn" alt="카카오톡채널 바로가기"/>
            </a>
          </div>
        </div>
        <br><br><br>
      </div>
    </div>

    <!-- modal-youtube --------------------------------->
    <div
      class="modal modal-youtube modal-bg"
      :class="{ show: youtubeModal }"
    >
      <modal
        v-if="youtubeModal"
        @close="youtubeModal = false"
      >
        <template slot="body">
          <iframe class="youtube-modal-iframe"
                  frameborder="0"
                  allowfullscreen="1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title="YouTube video player"
                  width="854"
                  height="480"
                  :src="`https://www.youtube.com/embed/${videoId}?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&playlist=${videoId}&mute=0`">
          </iframe>
        </template>
      </modal>
    </div>

  </div>
</template>

<script>
  import Mixins from "@/plugins/basicMixins";
  import { Modal } from "@/components";

  export default {
    name: "Index",
    components: {
      Modal,
    },
    mixins: [Mixins.HeaderImage],
    bodyClass: "index-page",

    mounted() {
      window.addEventListener("resize", this.leafActive);
      document.addEventListener("scroll", this.scrollListener);

      const pageHeaderVideo = document.getElementById('bg_video');

      pageHeaderVideo.onloadeddata = function() {
        pageHeaderVideo.play();
      };
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.leafActive);
      document.removeEventListener("scroll", this.scrollListener);
    },
    methods: {
      leafActive() {
        window.resizeThrottler(this.setIFrameHeight);
        if (window.innerWidth < 768) {
          this.leafShow = false;
        } else {
          this.leafShow = true;
        }
      },

      openYoutubeModal(id) {
        this.videoId = id;
        this.youtubeModal = true;

        window.resizeThrottler(this.setIFrameHeight);
      },

      setIFrameHeight() {
        const iFrame = document.querySelector('.youtube-modal-iframe');

        if (iFrame) {
          iFrame.style.height =  `${iFrame.offsetWidth * 9 / 16}px`;
        }
      }
    },
    data() {
      return {
        leafShow: false,
        youtubeModal: false,
        mediaModal: false,
        videoId: null,
        resource: {
          marketLink: {
            google: {
              img: require("@/assets/img/pages/main_bt_anipang2.png"),
              href: '',
            },
            apple: {
              img: require("@/assets/img/pages/main_bt_anipang2.png"),
              href: '',
            },
            onestore: {
              img: require("@/assets/img/pages/main_bt_anipang2.png"),
              href: '',
            },
          },
          
          button: {
            link1: require("@/assets/img/pages/main_bt_anipang2.png"),
            link2: require("@/assets/img/pages/main_bt_anipang3.png"),
            link3: require("@/assets/img/pages/main_bt_anipang4.png"),
          },
          section0: {
            title: require("@/assets/img/pages/main_title.png"),
            poster: require("@/assets/videos/anipang_web_horizontal.png"),
            mp4: require("@/assets/videos/anipang_web_horizontal.mp4"),
            // main_bt_emoji: require("@/assets/img/pages/main_bt_emoji.png"),
          },
          section1: {
            title: require("@/assets/img/pages/section1_title.png"),
            title_deco: require("@/assets/img/pages/section1_event1_sub_title_deco.png"),
            event1: {
              title: require("@/assets/img/pages/section1_event1_sub_title.png"),
              a2: require("@/assets/img/pages/section1_event1_a2.png"),
              a3: require("@/assets/img/pages/section1_event1_a3.png"),
              a4: require("@/assets/img/pages/section1_event1_a4.png"),
            },
            event2: {
              title: require("@/assets/img/pages/section1_event2_sub_title.png"),
              a2: require("@/assets/img/pages/section1_event2_a2.png"),
              a3: require("@/assets/img/pages/section1_event2_a3.png"),
              a4: require("@/assets/img/pages/section1_event2_a4.png"),
              text: require("@/assets/img/pages/section1_event2_text.png"),
            },
          },
          section2: {
            title: require("@/assets/img/pages/section2_title.png"),
            event1: {
              title: require("@/assets/img/pages/section2_title.png"),
              a2: require("@/assets/img/pages/section2_a2.png"),
              a3: require("@/assets/img/pages/section2_a3.png"),
              a4: require("@/assets/img/pages/section2_a4.png"),
            },
          },
          section3: {
            title: require("@/assets/img/pages/section3_title.png"),
            content: {
              c1: {
                btn: require("@/assets/img/pages/section3_01_TVC30s_bt.png"),
                youtubeId: 'a9RS_vXpStY',
              },
              c2: {
                btn: require("@/assets/img/pages/section3_02_TVC15s_bt.png"),
                youtubeId: 'lrGrxk4w0ns',
              },
              c3: {
                btn: require("@/assets/img/pages/section3_03_making_bt.png"),
                youtubeId: 'D1_jWX2I4p0',
              },
              c4: {
                btn: require("@/assets/img/pages/section3_04_making_bt.png"),
                youtubeId: '5u8rD3VP9Nk',
              },
            },
            comingSoon: require("@/assets/img/pages/section3_04_making_comingsoon_bt.png"),
            more: require("@/assets/img/pages/section3_bt.png"),
            player: null,
          },
          section4: {
            title: require("@/assets/img/pages/section4_title.png"),
            event1: {
              a2: require("@/assets/img/pages/section4_sacheonseong_bt.png"),
              a3: require("@/assets/img/pages/section4_shanghai_bt.png"),
              a4: require("@/assets/img/pages/section4_touch_bt.png"),
              text: require("@/assets/img/pages/section4_text.png"),
              btn: require("@/assets/img/pages/section4_bt.png"),
            },
          },
        },
      };
    },
  };
</script>
<style lang="scss">
  .vertical-nav-active {
    display: block;
  }

  @media all and (min-width: 991px) {
    .btn-container {
      display: flex;
    }

  }
  @media all and (max-width: 768px) {
    .vertical-nav-active {
      display: none;
    }
    //.section0
    //.link-button {
    //  width: 200px;
    //  text-align: center;
    //  margin: 0 auto;
    //}
    //.main_bt_emoji {
    //  width: 200px;
    //  margin: 0 auto;
    //}
    //
    //.video_button {
    //  button {
    //    width: 50px;
    //  }
    //}
  }

  .section0 {
    max-width: 2560px;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    margin: 0 auto;
    background-color: black;
    z-index: 500;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .7;
      background-color: rgba(0,0,0,.1);
      background-repeat: repeat;
      background-position: 50%;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHklEQVQImWNgYGDYDMVgwARjwAAjEhusClUFAwMDAGcEAh8XMK2iAAAAAElFTkSuQmCC);
      z-index: 1;
      -webkit-animation-duration: 20s;
      animation-duration: 20s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: cubic-bezier(.25,.25,.75,.75);
      animation-timing-function: cubic-bezier(.25,.25,.75,.75);
    }

    .video_button {
      button {
        background: none;
        position: relative;
        border: none;
        outline: none;
        cursor: pointer;
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        text-indent: -9999px;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2000%;
          background: url('../assets/img/pages/play.png') no-repeat;
          background-size: 100% auto;
          animation: videoButton 1s steps(20) infinite;
        }
      }
    }

    .bg-video {
      position: absolute;
      width: auto;
      height: 100%;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: inherit;
    }

    .main_bt_emoji {
      margin-top: 20px;
    }
  }

  .section1 {
    .title {
      background-image: url('../assets/img/pages/section1_title_deco_heart.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }

    h2 {
      text-align: center;
    }

    .container {
      width: 100%;
      border-radius: 10px;
      border: 4px #ffa38a solid;
      background-color: #fffdf1;

      .md-layout {
        margin: 0 auto;
        max-width: 1000px;

        .md-layout-item {
          padding-bottom: 20px;

          img {
            width: 100%;
          }
        }
      }
      .warning {
        text-align: center;
      }
    }
  }

  .section2 {
    h2 {
      text-align: center;
    }

    .container {
      width: 100%;

      .md-layout {

        .md-layout-item {
          img {
            width: 100%;
          }
        }
      }
    }
  }

  .section3 {
    background-color: white;

    h2 {
      text-align: center;
    }

    .container {
      width: 100%;

      .md-layout {
        .md-layout-item {
          .media-item {
            display: block;
            background: none;
            position: relative;
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            padding-bottom: 20px;

            img {
              width: 100%;
            }
          }
        }
      }
      .more-info {
        text-align: center;
      }
    }
  }

  .section4 {
    h2 {
      text-align: center;
    }

    .container {
      width: 100%;

      .md-layout {
        .md-layout-item {
          text-align: center;

        }
      }
      .more-info {
        text-align: center;
      }
    }
  }

  .modal-youtube {
    &.show {
      background-color: rgba(0, 0, 0, .5);
    }
    .modal-container {
      max-width: inherit;
      width: 80vw;
      margin: 0 auto;
      position: relative;
      background-color: transparent;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0;
      border: none;

      .modal-header {
        display: none;
      }

      .modal-body {
        height: 10px;
        padding: 0;
        margin:0 auto;

        .youtube-modal-iframe {
          /*height: 50vh;*/
          width: 854px;
          height: 480px;
          margin:0 auto;
        }
      }
    }
  }

  @keyframes videoButton {
    to {
      top: -2000%;
    }
  }
</style>
